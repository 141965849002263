import React, { useState } from 'react';
import thumbsUp from './images/thumbs_up.svg';
import thumbsDown from './images/thumbs_down.svg';
import './styles.sass';
import '../translations/componentsInit';
import { useTranslation } from 'react-i18next';
import makeRequest from '../../lib/fetchService';
import { amplitude_tracking } from 'helpers/amplitudeTracking';
import * as Sentry from '@sentry/browser';

const FeedbackMessage = ({ message, threadId, chatAnswerId, question, answer }) => {
  const { t } = useTranslation();
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const handleFeedback = async (feedback) => {
    setFeedbackGiven(true);
    setSelectedFeedback(feedback);
    const apiUrl = `/sofabuddy_chats/${chatAnswerId}`
    const rating = feedback === 'up' ? 'thumps_up' : 'thumps_down';
    amplitude_tracking('sofabuddy_csat_given', rating);
    try {
      await makeRequest(apiUrl, 'PATCH', {
        user_question: question,
        chat_answer: answer,
        chat_thread_id: threadId,
        chat_answer_id: chatAnswerId,
        rating: rating
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div
      className={`${feedbackGiven ? 'feedback-message--feedback-given' : 'feedback-message'}`}
    >
      {!feedbackGiven && <p className="text-xsmall">{message}</p>}
      {!feedbackGiven && (
        <>
          <button
            type="button"
            className="feedback-button"
            onClick={() => handleFeedback('down')}
            aria-label= {t('sofabuddy_chat_bot.thumbs_down_button_aria_label')}
          >
            <img src={thumbsDown} alt= {t('sofabuddy_chat_bot.thumbs_down_button_alt_text')} />
          </button>
          <button
            type="button"
            className="feedback-button"
            onClick={() => handleFeedback('up')}
            aria-label= {t('sofabuddy_chat_bot.thumbs_up_button_aria_label')}
          >
            <img src={thumbsUp} alt= {t('sofabuddy_chat_bot.thumbs_up_button_alt_text')} />
          </button>
        </>
      )}
      {feedbackGiven && selectedFeedback === 'down' && (
        <button
          type="button"
          className="feedback-button"
          aria-label= {t('sofabuddy_chat_bot.thumbs_down_button_aria_label')}
        >
          <img src={thumbsDown} alt= {t('sofabuddy_chat_bot.thumbs_down_button_alt_text')} />
        </button>
      )}
      {feedbackGiven && selectedFeedback === 'up' && (
        <button
          type="button"
          className="feedback-button"
          aria-label= {t('sofabuddy_chat_bot.thumbs_up_button_aria_label')}
        >
          <img src={thumbsUp} alt= {t('sofabuddy_chat_bot.thumbs_up_button_alt_text')} />
        </button>
      )}
    </div>
  );
};

export default FeedbackMessage;