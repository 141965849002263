export const amplitude_tracking = (eventName, rating) => {
  let amplitudeSofabuddyProperties = {};
  const user = window.Sofatutor?.user || {};
  const profileProperties = {
    profile_id: user.profile_id,
    profile_type: user.profile_type,
    profile_level: user.level
  };


  switch(eventName){
    case 'sofabuddy_csat_given':
      amplitudeSofabuddyProperties = {
        ...profileProperties,
        csat_valuation: rating
      };
      break;
  }
  if (
    window.Sofatutor &&
    window.Sofatutor.tracking &&
    window.Sofatutor.tracking.amplitude
  ) {
    window.Sofatutor.tracking.amplitude.track(
      eventName,
      amplitudeSofabuddyProperties
    );
  }
};
