import React, { useState, useMemo } from 'react';
import ChatBot, {
  MessagesContext,
  StylesContext
} from 'react-chatbotify';
import OpenAI from 'openai';
import * as Sentry from '@sentry/browser';
import './sofabuddy_chatbot/styles.sass';
import makeRequest from '../lib/fetchService';
import './translations/componentsInit';
import closeChatIcon from '/app/javascript/icons/svgs/minus-white.svg';
import { useTranslation } from 'react-i18next';
import ErrorMessage from './sofabuddy_chatbot/ErrorMessage';
import IntroMessage from './sofabuddy_chatbot/IntroMessage';
import { chatbotSettings } from './sofabuddy_chatbot/sofabuddySettings';
import FeedbackMessage from './sofabuddy_chatbot/FeedbackMessage';

const defaultStyles = {
  chatWindowStyle: {
    left: '1rem',
    height: '48.75rem',
    width: '43.75rem'
  },
  chatButtonStyle: {
    left: '1rem'
  },
  headerStyle: {
    height: '4rem',
    background: '#7866d3',
    fontWeight: 'bold',
    fontSize: '1.375rem',
    justifyContent: 'center',
    alignItems: 'center'
  },
  chatHistoryButtonHoveredStyle: {
    background: '#E6E8E5',
    borderRadius: '.25rem',
    color: '#3C3C3C',
    fontSize: '1rem',
    border: '1px solid #000'
  },
  botBubbleStyle: {
    fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
    color: '#333',
    backgroundColor: '#E9E6F8'
  },
  userBubbleStyle: {
    color: '#333',
    backgroundColor: '#FDEB99'
  },
  chatInputAreaStyle: {
    border: '1px solid #E1E1E1',
    borderRadius: '.5rem',
    boxSizing: 'border-box'
  },
  chatInputAreaFocusedStyle: {
    boxShadow: '#7866d3 0 0 0.3125rem'
  },
  sendButtonStyle: {
    backgroundColor: '#7866d3',
    height: '2.5rem'
  },
  sendButtonHoveredStyle: {
    backgroundColor: '#4b33c4',
    height: '2.5rem'
  },
  chatInputContainerStyle: {
    display: 'none'
  },
  footerStyle: {
    display: 'none'
  },
  chatHistoryButtonStyle: {
    display: 'none'
  }
}

const startChatStyles = {
  chatInputContainerStyle: {
    display: 'flex',
    padding: '1rem 1.5rem',
    borderTop: '1px solid #E1E1E1'
  },
  chatHistoryButtonStyle: {
    display: 'inline-flex',
    background: '#E6E8E5',
    borderRadius: '.25rem',
    color: '#3C3C3C',
    fontSize: '1rem'
  },
  footerStyle: {
    display: 'flex',
    background: '#F8F8F8',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Helvetica, Roboto, Arial, sans-serif',
    fontSize: '0.875rem',
    padding: '0.75rem',
    borderTop: '1px solid #E1E1E1'
  }
}

const SofabuddyChatBot = ({ sofabuddyCookieExists, sofabuddyThreadId, currentProfileId }) => {
  Sentry.init({
    dsn: 'https://3c7dcb320f67c6247aefb17f41c03901@o469049.ingest.us.sentry.io/4507823094693888',
    environment: process.env.NODE_ENV,
    enabled: process.env.NODE_ENV === 'production',
    release: 'sofabuddy'
  });

  const { t } = useTranslation();
  const apiKey = window.Sofatutor.Sofabuddy.openAi.apiKey;
  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true
  });

  const [styles, setStyles] = useState(defaultStyles);
  const [hasError, setHasError] = useState(false);
  const [messages, setMessages] = useState([]);
  const [thread, setThread] = useState(null);
  const assistantId = 'asst_RGtBOYyGoRHTpPD7KWHNIpVo';
  const welcomeMessage = sofabuddyCookieExists
    ? t('sofabuddy_chat_bot.return_welcome_message')
    : t('sofabuddy_chat_bot.first_welcome_message');

  const clearMessages = () => {
    setMessages(prev => prev.filter(msg => msg.sender === 'system'));
  };

  const startChat = goToPath => {
    if (!sofabuddyCookieExists) {
      clearMessages();
    }
    setStyles({
      ...styles,
      ...startChatStyles
    });
      goToPath('welcome');
  };

  const setCookie = async threadId => {
    const apiUrl = '/sofabuddy_chat_bot_used';
    makeRequest(apiUrl, 'PATCH', { thread_id: threadId });
  };

  const retrieveOrCreateThread = async () => {
    if (window.Sofatutor.env == 'test') {
      return {
        id: 'test_thread_id'
      };
    }

    try {
      if (sofabuddyThreadId) {
        const existingThread = await openai.beta.threads.retrieve(
          sofabuddyThreadId
        );
        setThread(existingThread);
        return existingThread;
      } else {
        const newThread = await openai.beta.threads.create();
        setThread(newThread);
        setCookie(newThread.id);
        if (window.Sofatutor.profileLevel) {
          await setClassLevel(newThread.id);
        }
        return newThread;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const setClassLevel =
    async threadId => {
      try {
        await openai.beta.threads.messages.create(threadId, {
          role: 'assistant',
          content: `The Student you are teaching is in class level ${window.Sofatutor.profileLevel}. Use this information to adjust your teaching style.`
        });

        const run = openai.beta.threads.runs.stream(threadId, {
          assistant_id: assistantId
        });

        await run.finalRun();
      } catch (error) {
        Sentry.captureException(error);
      }
    };

  const sendMessage = async (params, thread) => {
    setHasError(false);

    if (window.Sofatutor.env == 'test') {
      await params.streamMessage('This is a test message');
      return;
    }
    let text = '';

    try {
      const chatAnswerId = crypto.randomUUID();
      await openai.beta.threads.messages.create(thread.id, {
        role: 'user',
        content: params.userInput
      });
      const run = openai.beta.threads.runs.stream(thread.id, {
        assistant_id: assistantId
      });

      run.on('textDelta', async delta => {
        text += delta.value || '';
        await params.streamMessage(text);
      });

      await run.finalRun();
      trackConversation(params.userInput, text, thread.id, chatAnswerId);
      await params.injectMessage(<FeedbackMessage message={t('sofabuddy_chat_bot.feedback_message')} threadId={thread.id} chatAnswerId={chatAnswerId} question={params.userInput} answer={text} />);
    } catch (error) {
      Sentry.captureException(error);
      await params.injectMessage(
        await params.injectMessage(<ErrorMessage message={t('sofabuddy_chat_bot.error_message')} />),
      );
      setHasError(true);
    }
  };

  const flow = {
    start: {
      component: params => {
        if (sofabuddyCookieExists) {
          startChat(params.goToPath);
        } else {
        return <IntroMessage startChat={() => startChat(params.goToPath)} />;
        }
      }
    },
    welcome: {
      message: sofabuddyThreadId ? '' : welcomeMessage,
      path: 'loop'
    },
    loop: {
      message: async params => {
        if (!thread) {
          const createdThread = await retrieveOrCreateThread();
          if (createdThread) {
            await sendMessage(params, createdThread);
          } else {
            setHasError(true);
          }
        } else {
          await sendMessage(params, thread);
        }
      },
      path: () => (hasError ? 'start' : 'loop')
    }
  };

  const trackConversation = async (question, answer, threadId, chatAnswerId) => {
    try {
      const trackUrl = window.Sofatutor.Sofabuddy.trackUsageDataUrl;
      makeRequest(trackUrl, 'POST', {
        question: question,
        answer: answer,
        chat_answer_id: chatAnswerId,
        thread_id: threadId
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const setStorageKey = () => {
    if (currentProfileId) {
      return currentProfileId;
    } else {
      return 'no_profile_id';
    }
  }

  const storageKey = useMemo(() => setStorageKey(), [currentProfileId]);

  const settings = useMemo(() =>
    chatbotSettings(t, closeChatIcon, sofabuddyThreadId, storageKey)
  ,[t, closeChatIcon, sofabuddyThreadId, storageKey]);

  return (
    <>
      <StylesContext.Provider value={{ styles: styles, setStyles: setStyles }}>
        <MessagesContext.Provider
          value={{ messages: messages, setMessages: setMessages }}
        >
          <ChatBot
            styles={styles}
            settings={settings}
            flow={flow}
          />
        </MessagesContext.Provider>
      </StylesContext.Provider>
    </>
  );
};

export default SofabuddyChatBot;
